import React from "react";
import { graphql } from "gatsby";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import cx from "classnames";
import { renderForMobile } from "utils/renderForMobile";
import {
  navbar,
  banner,
  whatsAppContact,
  whatsAappUrlForPage,
  stickyBlocks,
  contactUsCard,
  navBarContact,
  announcementStrip,
} from "utils/renderBlocks";
import FontLoad from "components/FontLoad";
import { GuestOptionsBar } from "components/GuestOptionsBar";
import { WhatsAppFloat } from "components/Design/FloatingButton";
import RoomSelectionSearchDrawer from "components/Design/Drawer/RoomSelectionSearchDrawer";
import { RenderBlock } from "components/RenderBlock";
import { DefaultLayout } from "components/DefaultLayout";
import { RoomLayout } from "components/RoomLayout";
import { OfferLayout } from "components/OfferLayout";
import { FOOTER, NAVBAR } from "store/constants/blockThemes";
import { WeddingPackageLayout } from "components/WeddingPackageLayout";
import { FooterBlock } from "components/Blocks/FooterBlock";
import "components/Layout/style.less";
import { ExperienceLayout } from "components/ExperienceLayout";
import { StickyCta } from "components/StickyCta";
import { transformObject } from "utils/transformv4";
import { getHotel } from "utils/getHotel";
import { SpaLayout } from "components/SpaLayout";

const valideCmsData = (cmsData) => {
  if (typeof cmsData === "undefined") return false;
  if (cmsData === null) return false;

  return true;
};

const PAGE_QUERY = gql`
  query PreviewPageQuery($id: ID!) {
    page(id: $id) {
      data {
        id
        attributes {
          slug
          layout
          wedding_packages {
            data {
              id
              attributes {
                title
                japanese_only
                starting_price
                display_currency
                wedding_venues {
                  data {
                    id
                    attributes {
                      title
                    }
                  }
                }
              }
            }
          }
          room_types {
            data {
              id
              attributes {
                name
                room_size
                bed_types
                adult_occupancy
                single_use_price
                twin_use_price
                view
                hotel {
                  data {
                    id
                    attributes {
                      location
                      name
                      hotel_code
                      check_in_time
                      check_out_time
                    }
                  }
                }
                room_class {
                  data {
                    id
                    attributes {
                      name
                      name_en
                    }
                  }
                }
              }
            }
          }
          offers {
            data {
              id
              attributes {
                booking_code {
                  data {
                    id
                    attributes {
                      booking_code
                    }
                  }
                }
                book_period {
                  id
                  start_date
                  end_date
                }
                stay_period {
                  id
                  start_date
                  end_date
                }
                blackout_dates {
                  id
                  start_date
                  end_date
                }
                minimum_stay
                is_package
                hotels {
                  data {
                    id
                    attributes {
                      location
                      hotel_code
                    }
                  }
                }
              }
            }
          }
          activities {
            data {
              id
              attributes {
                adult_price
                child_price
                hotels {
                  data {
                    id
                    attributes {
                      location
                      hotel_code
                    }
                  }
                }
              }
            }
          }
          blocks {
            __typename
            ... on ComponentPageNavbar {
              id
              destination
              highlight
              overlay
              hotel {
                data {
                  id
                  attributes {
                    phone
                    location
                    name
                    whatsapp_contact
                    custom_whatsapp_message
                    hotel_code
                    logo {
                      data {
                        id
                        attributes {
                          name
                          fullWidth
                          height
                          width
                          cdnURL
                          caption
                        }
                      }
                    }
                    logo_horizontal {
                      data {
                        id
                        attributes {
                          name
                          fullWidth
                          height
                          width
                          cdnURL
                          caption
                        }
                      }
                    }
                    logo_white {
                      data {
                        id
                        attributes {
                          name
                          fullWidth
                          height
                          width
                          cdnURL
                          caption
                        }
                      }
                    }
                    favicon {
                      data {
                        id
                        attributes {
                          name
                          fullWidth
                          height
                          width
                          cdnURL
                          caption
                        }
                      }
                    }
                    destinations {
                      data {
                        id
                        attributes {
                          logo {
                            data {
                              id
                              attributes {
                                name
                                fullWidth
                                height
                                width
                                cdnURL
                                caption
                              }
                            }
                          }
                          logo_horizontal {
                            data {
                              id
                              attributes {
                                name
                                fullWidth
                                height
                                width
                                cdnURL
                                caption
                              }
                            }
                          }
                          logo_white {
                            data {
                              id
                              attributes {
                                name
                                fullWidth
                                height
                                width
                                cdnURL
                                caption
                              }
                            }
                          }
                          favicon {
                            data {
                              id
                              attributes {
                                name
                                fullWidth
                                height
                                width
                                cdnURL
                                caption
                              }
                            }
                          }
                        }
                      }
                    }
                    offices(sort: "sequence") {
                      data {
                        id
                        attributes {
                          name
                          kind
                          title
                          email
                          phone
                          sequence
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on ComponentPageBannerVideo {
              id
              bannerVideoIcon: icon
              bannerMobile: mobile {
                data {
                  id
                  attributes {
                    cdnURL
                  }
                }
              }
              bannerDesktop: desktop {
                data {
                  id
                  attributes {
                    cdnURL
                  }
                }
              }
              title
              subtitle
              caption
              fallback_image_mobile {
                data {
                  id
                  attributes {
                    name
                    fullWidth
                    height
                    width
                    cdnURL
                    caption
                  }
                }
              }
              fallback_image_desktop {
                data {
                  id
                  attributes {
                    name
                    fullWidth
                    height
                    width
                    cdnURL
                    caption
                  }
                }
              }
            }
            ... on ComponentPageIntro {
              id
              caption
              description
            }
            ... on ComponentPageBannerImage {
              id
              mobile {
                data {
                  id
                  attributes {
                    name
                    fullWidth
                    height
                    width
                    cdnURL
                    caption
                  }
                }
              }
              desktop {
                data {
                  id
                  attributes {
                    name
                    fullWidth
                    height
                    width
                    cdnURL
                    caption
                  }
                }
              }
              caption
              bannerImageIcon: icon
              title
              subtitle
            }
            ... on ComponentPageTitle {
              id
              title
              titleTheme: theme
            }
            ... on ComponentPageText {
              id
              title
              textTheme: theme
              description
              reference
            }
            ... on ComponentPageContactUs {
              id
              introduction
              phone_number
              cta_text
              theme
              support_url
              support_reason
            }
            ... on ComponentPageStickyBanner {
              id
              description
              redirection_url
            }
            ... on ComponentPageOffers {
              id
              title
              description
              cta_text
              cta_url
              reference
              offersTheme: theme
              list {
                id
                offer {
                  data {
                    id
                    attributes {
                      name
                      title
                      title_en
                      description
                      inclusions
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      slug
                      hotels {
                        data {
                          id
                          attributes {
                            title
                            name
                          }
                        }
                      }
                    }
                  }
                }
                sequence
              }
            }
            ... on ComponentPageRestaurants {
              id
              restaurantTheme: theme
              title
              cta_text
              cta_url
              description
              reference
              list {
                id
                restaurant {
                  data {
                    id
                    attributes {
                      category
                      description
                      hotels {
                        data {
                          attributes {
                            custom_whatsapp_message
                          }
                        }
                      }
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      images {
                        data {
                          id
                          attributes {
                            name
                            title
                            title_en
                            subtitle
                            link
                            mobile {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                            desktop {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                          }
                        }
                      }
                      food_menu {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                      name
                      timings
                      title
                      title_en
                      whatsapp_contact
                      sequence
                    }
                  }
                }
              }
            }

            ... on ComponentPageStories {
              id
              title
              storiesTheme: theme
              list {
                id
                story {
                  data {
                    id
                    attributes {
                      name
                      title
                      story_images {
                        id
                        title
                        sequence
                        desktop {
                          data {
                            id
                            attributes {
                              name
                              fullWidth
                              height
                              width
                              cdnURL
                              caption
                            }
                          }
                        }
                        mobile {
                          data {
                            id
                            attributes {
                              name
                              fullWidth
                              height
                              width
                              cdnURL
                              caption
                            }
                          }
                        }
                      }
                      images {
                        data {
                          id
                          attributes {
                            name
                            title
                            title_en
                            subtitle
                            link
                            mobile {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                            desktop {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            ... on ComponentPageExperiences {
              id
              Theme
              cta_text
              cta_url
              title
              reference
              list {
                id
                sequence
                activity {
                  data {
                    id
                    attributes {
                      kind
                      name
                      slug
                      title
                      title_en
                      description
                      title_en
                      adult_price
                      child_price
                      itinerary {
                        id
                      }
                      notes
                      inclusions
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      activity_types {
                        data {
                          id
                          attributes {
                            name
                            title
                            title_en
                            name
                            sequence
                          }
                        }
                      }
                      hotels {
                        data {
                          id
                        }
                      }
                      show_detail
                      show_contact
                      sequence
                    }
                  }
                }
              }
            }
            ... on ComponentPageRooms {
              title
              description
              roomTheme: theme
              cta_text
              cta_url
              reference
              list {
                id
                sequence
                room_type {
                  data {
                    id
                    attributes {
                      name
                      name_en
                      description
                      short_description
                      slug
                      room_size
                      bed_types
                      adult_occupancy
                      single_use_price
                      twin_use_price
                      number_of_rooms
                      sequence
                      url
                      room_class {
                        data {
                          id
                          attributes {
                            name
                            name_en
                          }
                        }
                      }
                      room_features {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }
                      view

                      room_media {
                        data {
                          id
                          attributes {
                            page_images {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                            highlights {
                              data {
                                id
                                attributes {
                                  name
                                  title
                                  mobile {
                                    data {
                                      id
                                      attributes {
                                        name
                                        fullWidth
                                        height
                                        width
                                        cdnURL
                                        caption
                                      }
                                    }
                                  }
                                  desktop {
                                    data {
                                      id
                                      attributes {
                                        name
                                        fullWidth
                                        height
                                        width
                                        cdnURL
                                        caption
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            highlight_mobile {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                            highlight_desktop {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                          }
                        }
                      }
                      hotel {
                        data {
                          id
                          attributes {
                            location
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            ... on ComponentPageWeddingPackages {
              id
              weddingTheme: theme
              reference
              title
              cta_url
              cta_text
              list {
                id
                sequence
                wedding_package {
                  data {
                    id
                    attributes {
                      notes
                      inclusions
                      description
                      slug
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      name
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      title
                      title_en
                      starting_price
                      wedding_venues {
                        data {
                          id
                          attributes {
                            title
                            category
                            description
                            mobile {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                            desktop {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                            name
                            title
                            title_en
                            ceremony_capacity
                            covid19_capacity
                            reception_capacity
                            kind
                            sequence
                            video_link
                            hotels {
                              data {
                                id
                                attributes {
                                  hotel_code
                                }
                              }
                            }
                          }
                        }
                      }
                      japanese_only
                    }
                  }
                }
              }
            }

            ... on ComponentPageWeddingVenues {
              id
              title
              description
              weddingVenueTheme: theme
              cta_text
              cta_url
              reference
              list {
                id
                sequence
                wedding_venue {
                  data {
                    id
                    attributes {
                      category
                      description
                      hotels {
                        data {
                          id
                          attributes {
                            phone
                            location
                          }
                        }
                      }
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      name
                      title
                      title_en
                      ceremony_capacity
                      covid19_capacity
                      reception_capacity
                      kind
                      sequence
                      video_link
                      support_url
                      hotels {
                        data {
                          id
                          attributes {
                            location
                          }
                        }
                      }
                      images {
                        data {
                          id
                          attributes {
                            name
                            title
                            title_en
                            subtitle
                            link
                            mobile {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                            desktop {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            ... on ComponentPageIntro {
              id
              caption
              description
            }

            ... on ComponentPageFacilities {
              id
              title
              reference
              facilitiesTheme: theme
              list {
                id
                sequence
                facility {
                  data {
                    id
                    attributes {
                      name
                      title
                      description
                      operating_hours
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      images {
                        data {
                          id
                          attributes {
                            name
                            title
                            title_en
                            subtitle
                            link
                            mobile {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                            desktop {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            ... on ComponentPageCards {
              id
              icon
              title
              caption
              cardsTheme: theme
              description
              cta_link
              cta_text
              reference
              list {
                id
                title
                cardDescription: description
                cta_link
                mobile {
                  data {
                    id
                    attributes {
                      name
                      fullWidth
                      height
                      width
                      cdnURL
                      caption
                    }
                  }
                }
                desktop {
                  data {
                    id
                    attributes {
                      name
                      fullWidth
                      height
                      width
                      cdnURL
                      caption
                    }
                  }
                }
              }
            }

            ... on ComponentPageSpas {
              id
              title
              reference
              spaTheme: theme
              list {
                id
                sequence
                spa {
                  data {
                    id
                    attributes {
                      title
                      title_en
                      description
                      policy
                      duration_and_rate
                      show_detail
                      show_contact
                      slug
                      support_url
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      hotels {
                        data {
                          id
                          attributes {
                            phone
                            location
                          }
                        }
                      }
                      spa_treatments {
                        data {
                          id
                          attributes {
                            title
                            title_en
                            description
                            duration
                            spa_tags {
                              data {
                                id
                                attributes {
                                  title
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            ... on ComponentPageSpaTreatments {
              id
              title
              spaTreatmentsTheme: theme
              list {
                duration
                id
                title
                sequence
                spaTreatmentDescription: description
                cta_link
                cta_text
              }
            }

            ... on ComponentPageMeetingVenues {
              id
              title
              meetingVenueTheme: theme
              reference
              description
              cta_text
              cta_url
              list {
                id
                sequence
                meeting_venue {
                  data {
                    id
                    attributes {
                      title
                      description
                      kind
                      category
                      reception_capacity
                      round_table_capacity
                      u_shape_capacity
                      classroom_capacity
                      theatre_capacity
                      hollow_square_capacity
                      boardroom_style_capacity
                      seated_capacity
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      hotels {
                        data {
                          id
                          attributes {
                            phone
                            location
                          }
                        }
                      }
                      images {
                        data {
                          id
                          attributes {
                            name
                            title
                            title_en
                            subtitle
                            link
                            mobile {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                            desktop {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            ... on ComponentPageMeetingServices {
              id
              title
              meetingServiceTheme: theme
              reference
              list {
                id
                sequence
                meeting_service {
                  data {
                    id
                    attributes {
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      title
                      description
                      kind
                      available_options
                    }
                  }
                }
              }
            }

            ... on ComponentPageTabs {
              id
              references
              title
              tabIcon: icon
              tabsTheme: theme
            }

            ... on ComponentPageSchedule {
              id
              title
              scheduleTheme: theme
              list {
                id
                sequence
                start_date
                end_date
                type
              }
            }

            ... on ComponentPageItinerary {
              id
              title
              list {
                id
                title
                time
                sequence
              }
            }

            ... on ComponentPageBannerCarousel {
              id
              title
              subtitle
              bannerCarouselIcon: icon
              list {
                id
                desktop {
                  data {
                    id
                    attributes {
                      name
                      fullWidth
                      height
                      width
                      cdnURL
                      caption
                    }
                  }
                }
                mobile {
                  data {
                    id
                    attributes {
                      name
                      fullWidth
                      height
                      width
                      cdnURL
                      caption
                    }
                  }
                }
                sequence
              }
            }

            ... on ComponentPageWeddingPricing {
              id
            }
            ... on ComponentPageImageCard {
              id
              title
              description
              imageCardTheme: theme
              cta_text
              cta_link
              icons
              desktop {
                data {
                  id
                  attributes {
                    name
                    fullWidth
                    height
                    width
                    cdnURL
                    caption
                  }
                }
              }
              mobile {
                data {
                  id
                  attributes {
                    name
                    fullWidth
                    height
                    width
                    cdnURL
                    caption
                  }
                }
              }
            }

            ... on ComponentPageSeo {
              id
              meta_title
              meta_description
              open_graph_image {
                data {
                  id
                  attributes {
                    name
                    fullWidth
                    height
                    width
                    cdnURL
                    caption
                  }
                }
              }
            }
            ... on ComponentPageAwards {
              id
              list {
                id
                year
                description
              }
            }

            ... on ComponentPageButton {
              id
              cta_text
              cta_url
              description
              buttonTheme: theme
            }

            ... on ComponentPageOfferConditions {
              id
            }

            ... on ComponentPagePressReleases {
              id
              reference
              title
              list {
                id
                sequence
                press_release {
                  data {
                    id
                    attributes {
                      slug
                      summary
                      title
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      date
                      hotels {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            ... on ComponentPageFactSheets {
              id
              title
              description
              reference
              factSheetTheme: theme
              list {
                cta_link
                id
                title
                view_resources_and_fact_sheets
                desktop {
                  data {
                    id
                    attributes {
                      name
                      fullWidth
                      height
                      width
                      cdnURL
                      caption
                    }
                  }
                }
                mobile {
                  data {
                    id
                    attributes {
                      name
                      fullWidth
                      height
                      width
                      cdnURL
                      caption
                    }
                  }
                }
              }
            }
            ... on ComponentPageStickyCta {
              id
              title
              stickyTheme: theme
            }
            ... on ComponentPageViewRates {
              id
              viewRatesTheme: theme
            }
            ... on ComponentPageBoats {
              id
              list {
                sequence
                boat {
                  data {
                    id
                    attributes {
                      slug
                      title
                      description
                      available_days
                      size
                      capacity
                      features
                      speed
                      images {
                        data {
                          id
                          attributes {
                            name
                            title
                            title_en
                            subtitle
                            link
                            mobile {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                            desktop {
                              data {
                                id
                                attributes {
                                  name
                                  fullWidth
                                  height
                                  width
                                  cdnURL
                                  caption
                                }
                              }
                            }
                          }
                        }
                      }
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on ComponentPageCardsWithPopupGallery {
              id
              list {
                sequence
                title
                cardDescription: description
                desktop {
                  data {
                    id
                    attributes {
                      name
                      fullWidth
                      height
                      width
                      cdnURL
                      caption
                    }
                  }
                }
                mobile {
                  data {
                    id
                    attributes {
                      name
                      fullWidth
                      height
                      width
                      cdnURL
                      caption
                    }
                  }
                }
                images {
                  data {
                    attributes {
                      title
                      subtitle
                      desktop {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                      mobile {
                        data {
                          id
                          attributes {
                            name
                            fullWidth
                            height
                            width
                            cdnURL
                            caption
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on ComponentPageRoomPricing {
              id
            }
          }
        }
      }
    }
  }
`;

const RenderPreviewPage = ({ data }) => {
  const {
    allNavigationMenuJson: { nodes: navigations },
    cms,
    allSocialMediaJson: { nodes: allSocialMedia },
  } = data;

  const {
    hotelsByLocale,
    hotelPropertyBali,
    destinationImagesSections,
    supportDrawers,
    lvxLogo: [lvxLogo],
  } = transformObject(cms);

  const breakpoints = useBreakpoint();
  const isMobile = renderForMobile(breakpoints);
  const { language } = useI18next();

  const params = new URLSearchParams(
    typeof window === "undefined"
      ? "https://example.com?page=0"
      : window.location.search
  );
  const pageId = params.get("page");

  const {
    loading,
    error,
    data: cmsData,
  } = useQuery(PAGE_QUERY, {
    variables: { id: pageId },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  if (!valideCmsData(cmsData)) return <></>;

  const { page } = transformObject(cmsData);

  const blocks = page.blocks;

  const navbarBlock = navbar(blocks);
  const bannerBlock = banner(blocks);
  const contactUsBlock = contactUsCard(blocks);
  const stickyBlock = stickyBlocks(blocks);
  const announcementStripBlock = announcementStrip(blocks);
  const destination = navbarBlock?.destination;
  const navbarContactBlock = navBarContact(blocks);

  const whatsAppContactBlock = whatsAppContact(blocks);
  const formattedWhatsAppUrl = whatsAappUrlForPage({
    hotel: getHotel(navbarBlock, hotelsByLocale),
    destination: navbarBlock.destination,
    whatsAppContactBlock,
  });

  return (
    <>
      <FontLoad />
      <div
        className={cx("antialiased", {
          /* helps in wrapping words at spaces for Japanese language */
          "font-ja wrap-word": language === "ja",
          "font-zh": language === "zh",
          "font-ko": language === "ko",
        })}
      >
        <div className="hidden lg:block lg:sticky lg:top-0 lg:z-100">
          <GuestOptionsBar
            destinationCards={destinationImagesSections[0].images}
            hotel={navbarBlock.destination}
            cartCount={0}
            navigationHotel={navbarBlock.hotel?.name}
            hotelPropertyBali={hotelPropertyBali}
          />
        </div>
        <div className="antialiased">
          <RenderBlock
            key={`navbar-${navbarBlock.id}`}
            block={{
              ...navbarBlock,
              allHotels: hotelsByLocale,
              navigations,
              destinationImagesSections,
              supportDrawers,
              theme: NAVBAR,
              formattedWhatsAppUrl,
              navbarContactBlock,
              announcementStrip: announcementStripBlock,
            }}
          />
          {bannerBlock && (
            <RenderBlock
              key={`${bannerBlock.__typename}:${bannerBlock.id}`}
              block={bannerBlock}
            />
          )}
          <div className="overflow-x-hidden">
            {page.layout === "DEFAULT" && <DefaultLayout blocks={blocks} />}
            {page.layout === "LANDING" && (
              <DefaultLayout blocks={blocks} isLanding />
            )}
            {page.layout === "ROOM" && (
              <RoomLayout
                room_type={page.room_types[0]}
                blocks={blocks}
                pageContext={{}}
              />
            )}
            {page.layout === "OFFER" && (
              <OfferLayout offer={page.offers[0]} blocks={blocks} />
            )}
            {page.layout === "WEDDING_PACKAGE" && (
              <WeddingPackageLayout
                blocks={blocks}
                weddingPackage={page.wedding_packages[0]}
              />
            )}
            {page.layout === "EXPERIENCE" && (
              <ExperienceLayout
                activity={page.activities[0]}
                isMobile={isMobile}
                blocks={blocks}
              />
            )}
            {page.layout === "SPA" && <SpaLayout blocks={blocks} />}
          </div>
          <FooterBlock
            {...{
              ...navbarBlock,
              allHotels: hotelsByLocale,
              allSocialMedia,
              navigations,
              destinationImagesSections,
              supportDrawers,
              theme: FOOTER,
              lvxLogo,
            }}
          />
          <WhatsAppFloat link={formattedWhatsAppUrl} />
          <RoomSelectionSearchDrawer
            cta={(onClick, sticky) => (
              <div
                className={cx(
                  "fixed lg:hidden bottom-0 w-full z-20 transition-all duration-500",
                  {
                    "opacity-0 invisible": !sticky,
                    "opacity-100 visible": sticky,
                  }
                )}
              >
                <StickyCta
                  stickyBlock={stickyBlock}
                  contactUsBlock={contactUsBlock}
                  onClick={onClick}
                  destination={destination}
                  whatsAppUrl={formattedWhatsAppUrl}
                />
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};

const PreviewPage = ({ data }) => {
  const client = new ApolloClient({
    uri: "https://cms4.ayana.com/graphql",
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <RenderPreviewPage data={data} />
    </ApolloProvider>
  );
};

export default PreviewPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson {
      ...LocalNavigation
    }
    allSocialMediaJson {
      nodes {
        hotel
        code
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }
    cms {
      ...PageLayout
    }
  }
`;
